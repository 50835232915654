var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0",attrs:{"cols":"12","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-success","pill":""}},[_vm._v(" Total : "+_vm._s(_vm.formatCurrency(_vm.totalList, false))+" ")])],1)],1)],1),_c('b-table',{ref:"refListTable",staticClass:"position-relative",attrs:{"id":"refListTable","no-provider-paging":"","no-provider-filtering":"","fields":_vm.tableColumns,"items":_vm.itemLists,"responsive":"","primary-key":"id","show-empty":"","busy":_vm.isBusy,"empty-text":_vm.$t('globalSingular.noMatching'),"sort-desc":_vm.isSortDirDesc},on:{"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2 d-flex flex-column justify-content-center"},[_c('div',{staticClass:"text mb-1"},[_c('b-spinner',{staticClass:"align-middle"})],1),_c('strong',[_vm._v(_vm._s(_vm.$t('globalActions.fetchingDataAPI')))])])]},proxy:true},{key:"head()",fn:function(ref){
var field = ref.field;
return [_c('table-search-column',{attrs:{"field":field,"fields":_vm.tableColumns,"focused-field":_vm.focusedSearchField},on:{"set-focused-field":function (value) { return _vm.focusedSearchField = value; },"search-data":function (params) { return _vm.searchData(params); }}})]}},{key:"cell(invoice_no)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"font-weight-bolder",attrs:{"to":{ name: 'apps-transactions-bacc-payment-per-voucher-detail', params: { id: item.id, agent: item.contact_id }}}},[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.invoice_no)+" ")])])]}},{key:"cell(sum_of_bill_amount)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:['font-weight-bolder', 'd-block', 'text-nowrap', (item.sum_of_bill_amount > 0) ? 'text-success' : 'text-danger' ]},[(item.sum_of_bill_amount > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.formatCurrency(item.sum_of_bill_amount, false))+" ")]):_vm._e()])]}},{key:"cell(outstanding_amount)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:['font-weight-bolder', 'd-block', 'text-nowrap', (item.invoice_amount > 0) ? 'text-success' : 'text-danger' ]},[(item.outstanding_amount > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.formatCurrency(item.outstanding_amount, false))+" ")]):_vm._e()])]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mt-1 mb-0 mt-sm-0",attrs:{"total-rows":_vm.totalList,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","aria-controls":"refInvoiceListTable"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }