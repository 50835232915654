<template>
  <b-card no-body>
    <div class="m-2">
      <b-row class="justify-content-end">
        <!-- <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              @keyup="GetTotal"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col> -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            pill
          >
            Total : {{ formatCurrency(totalList, false) }}
          </b-button>
        </b-col>
      </b-row>
      <!-- <div class="demo-inline-spacing">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="outline-success"
          pill
        >
          Total : {{formatCurrency(totals, false)}}
        </b-button>
      </div> -->
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <!-- Column: form number -->
      <template #cell(invoice_no)="{ item }">
        <b-link
          :to="{ name: 'apps-transactions-bacc-payment-per-voucher-detail', params: { id: item.id, agent: item.contact_id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.invoice_no }}
          </span>
        </b-link>
      </template>

      <template #cell(sum_of_bill_amount)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (item.sum_of_bill_amount > 0) ? 'text-success' : 'text-danger' ]">
          <div v-if="item.sum_of_bill_amount > 0">
            {{ formatCurrency(item.sum_of_bill_amount, false) }}
          </div>
        </span>
      </template>

      <template #cell(outstanding_amount)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (item.invoice_amount > 0) ? 'text-success' : 'text-danger' ]">
          <div v-if="item.outstanding_amount > 0">
            {{ formatCurrency(item.outstanding_amount, false) }}
          </div>
        </span>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mt-1 mb-0 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BPagination,
  BLink
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import useListTablePage from '@/comp-functions/useListTablePage'
import { formatCurrency } from '@/utils/formatter'
import useLocalization from '@/comp-functions/utils/useLocalization'
import { ref } from '@vue/composition-api'
// import { BBadge } from 'bootstrap-vue'

import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BLink,
    // BBadge,
    BPagination,
    BButton,
    TableSearchColumn
  },


  directives: {
    Ripple,
  },


  setup () {
    const { $t }   = useLocalization()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      changePerPage,
      searchData
    } = useListTablePage({ url: 'transaction/purchase/posting-ap-payment-outstanding/bacc?' })

    const tableColumns = ref([
      {
        key: 'invoice_no',
        label: $t('globalSingular.invoiceNo'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: 'sum_of_bill_amount',
        label: $t('globalSingular.amount'),
        thClass: 'px-1 text-right',
        tdClass: 'px-1 text-right',
        search: { type: 'number', value: 0 }
      },
      {
        key: 'outstanding_amount',
        label: $t('globalSingular.outstandingAmount'),
        thClass: 'px-1 text-right',
        tdClass: 'px-1 text-right',
        search: { type: 'number', value: 0 }
      },
      {
        key: 'office.label',
        label: $t('globalSingular.branch'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'select',
          value: '',
          key: 'office_id',
          url: 'value/getBranch'
        }
      },
      {
        key: 'code',
        label: $t('globalSingular.formPaymentNo'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: 'contact.label',
        label: $t('globalSingular.agent'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'select',
          value: '',
          key: 'contact_id',
          url: 'value/contact/agent'
        }
      }
    ])

    return {
      tableColumns,
      formatCurrency,
      changePerPage,
      searchData,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      focusedSearchField
    }
  },


  computed: {
    companyActive () {
      return this.$store.state.user.currentCompany
    }
  }
}
</script>

